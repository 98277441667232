<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-07 18:45 -->
<!-- Description:  -->
<template>
  <div>
    <h4>Control por Rango</h4>
    <div class="row">
      <div class="col-lg-3 mb-3">
        <label for="">Fecha Inicio</label>
        <input type="date" class="form-control" v-model="date_ini">
      </div>
      <div class="col-lg-3 mb-3">
        <label for="">Fecha Fin</label>
        <input type="date" class="form-control" v-model="date_end">
      </div>
      <div class="col-lg-3 mb-3">
        <label for="">Doctor(a)</label>
        <SelectEmployee v-model="employee_id" :specialties="config_treatments.specialty_id"></SelectEmployee>
      </div>
    </div>
    <div class="mb-3">
      <button type="button" class="btn btn-primary" @click="reload()">
        Generar
      </button>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-6">
        <ControlTableResumeByEmployee ref="elControlTableResumeByEmployee">
        </ControlTableResumeByEmployee>
      </div>
      <div class="col-lg-4">
        <h4>VENTAS POR M. PAGO</h4>
        <TableResumePayMethod ref="elTableResumePayMethod"></TableResumePayMethod>
        <!-- <TableResumePayMethod ref="elTableResumeOutput"></TableResumePayMethod> -->
        <h4>HISTORICO</h4>
        <LineChart ref="elResumeLineChart"></LineChart>
      </div>
    </div>
    <TableControlView :list="list"></TableControlView>
  </div>
</template>
<script>
import {myMoment} from "../../utils/myMoment";
import {DentalClinicService} from "../service";
import TableControlView from "./TableControlView.vue";
// import _ from "lodash";
import LineChart from "./LineChart.vue";
import ControlTableResumeByEmployee from "./ControlTableResumeByEmployee.vue";
import TableResumePayMethod from "./TableResumePayMethod.vue";
import {SelectEmployee} from "../../admin-module/index.js";
import {mapState} from "vuex";

// script content
export default {
  name: "ControlByMonth",
  components: {
    TableControlView,
    LineChart,
    ControlTableResumeByEmployee,
    TableResumePayMethod,
    SelectEmployee,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    date_ini: null,
    date_end: null,
    list: [],
    group_doctor: [],
    employee_id: null,
  }),

  computed: {
    ...mapState({
      config_treatments: s => s.config.dental.treatments
    })
  },

  // watch: {},

  mounted() {
    // this.reload()
  },

  methods: {
    async reload() {
      // [this.date_ini, this.date_end] = this.$refs.elSelectDateRange.getRange();
      this.$refs.elControlTableResumeByEmployee.reset();
      this.$refs.elTableResumePayMethod.reset();
      let res = await DentalClinicService.getControlRange({
        date_ini: this.date_ini,
        date_end: this.date_end,
        employee_id: this.employee_id,
      });
      this.list = res;
      // let groupDoctor = _.groupBy(res, "employee_id");
      // let groupDoctorResults = [];
      // for (let keyIt in groupDoctor) {
      //   let registers = groupDoctor[keyIt];
      //   let amountPaid = registers.reduce(function(p, c) {
      //     return p + parseFloat(c.amount_paid);
      //   }, 0);
      //   groupDoctorResults.push({
      //     name: keyIt > 0 ? registers[0].doctor : "Otros",
      //     employee_id: registers[0].employee_id,
      //     amount_paid: amountPaid,
      //   });
      // }
      // this.group_doctor = groupDoctorResults;
      let lastDate = myMoment(this.date_end);
      let resume = await DentalClinicService.getResumeMonthly({
        year: lastDate.year(),
        month: lastDate.month() + 1,
        months: 6,
      });
      this.generateResumeLineChart(resume);
      this.$refs.elControlTableResumeByEmployee.generate(res);
      this.$refs.elTableResumePayMethod.generate(res);
      // this.$refs.elTableResumeOutput.generate();
    },
    generateResumeLineChart(resume) {
      let sales = resume.sales.reverse();
      let outs = resume.outs.reverse();
      this.$refs.elResumeLineChart.generate({
        labels: sales.map((x) => `${x.year}-${x.month}`),
        datasets: [
          {
            label: "Ventas",
            data: sales.map((x) => x.amount),
            borderColor: "blue",
          },
          {
            label: "Gastos",
            data: outs.map((x) => x.amount),
            borderColor: "red",
          }
        ],
      });
    },
    //
    //
  },
};
</script>
<style scoped>
</style>
